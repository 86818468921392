@use './variables.scss' as vars;
// $speed: 0.15s;
@mixin ourTeam-box-style{
        height: 30vh;
        width: 15vw;
        border-radius: 1vw;
        background-color: var(--wave-fill-Green);
        position: relative;
        // transform: rotate(-3deg);
}

@mixin wave-style-down{
  // background-color: vars.$wave-fill;
  // display:flex;
  transform: rotate(180deg);
  position: absolute;
  left: 0;  
  right: 0;
}


// @mixin wave($fill: $wave-fill, $height, $transform: none, $position: static, $bottom: auto, $left: auto, $right: auto) {
//     fill: $fill;
//     display: flex;
//     width: 100%;
//     height: $height;
//     transform: $transform;
//     position: $position;
//     bottom: $bottom;
//     left: $left;
//     right: $right;
//   }


//   @mixin waveOptions($fill: $wave-fill,   $amplitude: 10, $points: 3, $speed: $wave-speed) {
//     @include wave($fill,$speed , $amplitude, $points );
//     // amplitude, speed, and points are not standard CSS properties, these should be part of Wave component props
//   }

        