/* customized colors */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none; 
  list-style: none;
}

/* customized fonts */
@font-face {
  font-family: "Proxima-Nova-Alt-Bold";
  src: url("./assets/Fonts/Proxima\ Nova\ Alt\ Bold.otf");
}
@font-face {
  font-family: "Proxima--Alt-LightNova";
  src: url("./assets/Fonts/Proxima\ Nova\ Alt\ Light.otf");
}

@font-face {
  font-family: "Proxima-Nova-Alt-Thin";
  src: url("./assets/Fonts/Proxima\ Nova\ Alt\ Thin.otf");
}

@font-face {
  font-family: "Proxima-Nova-Alt-Black";
  src: url("./assets/Fonts/Proxima\ Nova\ Black.otf");
}

@font-face {
  font-family: "Proxima-Nova-Bold";
  src: url("./assets/Fonts/Proxima\ Nova\ Bold.otf");
}

@font-face {
  font-family: "Proxima-Nova-Alt-Extrabold";
  src: url("./assets/Fonts/Proxima\ Nova\ Extrabold.otf");
}

@font-face {
  font-family: "Proxima-Nova-Thin";
  src: url("./assets/Fonts/Proxima\ Nova\ Thin.otf");
}

@font-face {
  font-family: "Proxima-Nova-Regular";
  src: url("./assets/Fonts/ProximaNova-Regular.otf");
}

@font-face {
  font-family: "FontsFree-Net-Proxima-Nova-Sbold";
  src: url("./assets/Fonts/FontsFree-Net-Proxima-Nova-Sbold.otf");
}

@font-face {
  font-family: "Chobani-Serif-Web-Semibold-Regular";
  src: url("./assets/Fonts/Chobani\ Serif\ Web\ Semibold\ Regular.woff2.ttf");
}

:root {
  --light-color: hsl(85, 36%, 86%);
  --mid-Green: #436c08;
  --light-Green: #5b930b;
  --dark-Green: #273d07;
  --light-Orange: #fbba0a;
  --white-color: #ffffff;
  --very-light-Green:#a7ef4383;
  --low-light-Green:#d8ffa183;
  --wave-fill-Green:#DDE8CE
}
