
.hydImage-style{
  width: 100%;
  object-fit: cover;
}

.head-main{
    position: absolute;
    top: 10%;
    left: 7%;
}
.head-main h4{
    color: var(--mid-Green);
    margin-bottom: 0;
    font-family: Proxima-Nova-Regular;
    font-weight: 600;
}
.head-main h2{
    color: var(--mid-Green);
    font-family: Chobani-Serif-Web-Semibold-Regular;
    font-size:40px;
}
.wave-desgin{
    position: absolute;
    bottom: 0;
    z-index: -1;
}
.image-div{
  margin-top: 10vh;
}

@media all and (max-width: 480px) {
    .image-div .hydImage-style {
      width: 100%;
    }
  }
 
  
 
