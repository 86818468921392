.text1 {
    font-family: "Chobani-Serif-Web-Semibold-Regular";
    font-size: 2vw;
    color: var(--light-Green);
}

.text2 {
    font-family: "Proxima-Nova-Regular";
    font-size: 1.8vw;
    color: var(--light-Green);
    margin-bottom: 6vh; 
}

.text3 {
    font-family: "Proxima-Nova-Regular";
    font-size: 1.3vw; 
    color: var(--light-Green);
}

.underline {
    text-decoration: underline;
    text-underline-offset: 0.2em;
    text-decoration-color: var(--light-color);
}

.img_marginleft {
    margin-left: 5vw; 
}

.img_size {
    width: 10vw; 
    display: block;
}

@media (max-width: 768px) {
    .text1 {
        font-size: 3.2vw; 
    }

    .text2 {
        font-size: 2.8vw;
        margin-bottom: 5vh; 
    }

    .text3 {
        font-size: 1.6vw; 
    }

    .img_marginleft {
        margin-left: 4vw; 
    }

    .img_size {
        width: 12vw; 
    }
}

@media (max-width: 480px) {
    .text1 {
        font-size: 4.2vw; 
    }

    .text2 {
        font-size: 3.4vw !important; 
        margin-bottom: 4vh; 
    }

    .text3 {
        font-size: 2vw !important; 
    }

    .img_marginleft {
        margin-left: 3vw; 
    }

    .img_size {
        width: 15vw !important; 
    }
}
@media (max-width: 576px) {
    .text1 {
        font-size: 3.5vw; 
    }

    .text2 {
        font-size: 3vw; 
        margin-bottom: 4vh; 
    }

    .text3 {
        font-size: 1.9vw; 
    }

    .img_marginleft {
        margin-left: 3vw; 
    }

    .img_size {
        width: 14vw; 
    }
}
@media (max-width: 320px) {
    .text1 {
        font-size: 4.5vw; 
    }

    .text2 {
        font-size: 4.2vw; 
        margin-bottom: 2vh; 
    }

    .text3 {
        font-size: 3vw; 
    }

    .img_marginleft {
        margin-left: 1vw; 
    }

    .img_size {
        width: 21vw;
        margin-left: 5vw;
    }
}