.ourText{
    color: var(--light-Green);
    font-family: "Chobani-Serif-Web-Semibold-Regular";
    text-align: center;
    margin-top: 17.8vh;
    font-size: 2vw;
}
.ourTextone{
    text-align: center;
    color: var(--light-Green);
    font-size: 1.56vw;
    margin-top: 5vh;
    width: 50.7vw;
    font-family: "Proxima-Nova-Regular";
}
.strongDescription{
    font-family: "FontsFree-Net-Proxima-Nova-Sbold";
    font-size: 1.1vw;
}
.ourfont{
    font-size: 1.56vw;
    color: var(--mid-Green);
    font-family: "FontsFree-Net-Proxima-Nova-Sbold";
    text-align: center;
    margin-top: 4.1vh;
    margin-bottom: 4.1vh;
}
.ourfontfour{
    color: var(--mid-Green);
    font-family: "FontsFree-Net-Proxima-Nova-Sbold";
    text-align: center;
    font-size: 2vw;
    margin-top: 4.1vh;
    margin-bottom: 4.1vh;
    margin-left: 2vw;
}
.ourImages{
    height: 48vh;
    margin-top: 6.1vh;
    justify-content: center;
    margin-left: 4.55vw;
}
.wrapper{
    max-width: 81.3vw;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 11vh;
    justify-content: space-between;
    margin: 0 auto;
  }

.descText p{
    color: var(--light-Green);
    font-family: "Proxima-Nova-Regular";
    font-size: 1vw;
    /* display: flex; */
    /* justify-content: center; */
    margin-bottom:9vh ;
     /* width: 400px;  */
}
.ourfontone{
    width: 26vw;
    justify-content: center;
}
.paraDescription{
    width: 24vw;
   margin: 0 auto;
   /* margin-left: 10px; */
}
.ourfontthree{
    width: 24vw;
}


@media (min-width:320px) and (max-width:480px) {
    .ourText {
    color: var(--mid-Green);
    font-weight: 500;
    font-size: 6vw;
    font-family: "Chobani-Serif-Web-Semibold-Regular";
    margin-top: 25vh;
    }
    .ourTextone {
        font-size: 3.1vw;
        width: 78vw;
        margin-bottom: 6vh;
    }
    .ourImages {
        height: 40vh;
        margin-left: 15vw;
        margin-top: -2vw;
    }
    .ourfont {
        font-size: 4.6vw;
    }
    .descText p {
        font-size: 3.2vw;
        margin-left: 6vh;
    }
    .paraDescription {
        width: 65vw;
        text-align: left;
        margin-top: -2vw;
    }
    .strongDescription {
        font-size: 3.5vw;
    }
}
@media (min-width:481px) and (max-width:576px) {
        .ourText {
        color: var(--mid-Green);
        font-weight: 500;
        font-size: 6vw;
        font-family: "Chobani-Serif-Web-Semibold-Regular";
        text-align: center;
        margin-left: 10vw;
        margin-top: 30vh;
        }
        .ourTextone {
            font-size: 3.1vw;
            width: 78vw;
            margin-left: 5vw;
            margin-bottom: 10vh;
        }
        .ourImages {
            height: 45vh;
            margin-left: 25vw;
            margin-top: -2vw;
        }
        .ourfont {
            font-size: 4vw;
            margin-left: 10vw;
        }
        .descText p {
            font-size: 3.2vw;
            margin-left: 8vh;
        }
        .paraDescription {
            width: 65vw;
            text-align: left;
            margin-top: -2vw;
        }
        .strongDescription {
            font-size: 3.5vw;
        }
}
@media (min-width:577px) and (max-width:768px) {
    .ourText {
        color: var(--mid-Green);
        font-weight: 500;
        font-size: 6vw;
        font-family: "Chobani-Serif-Web-Semibold-Regular";
        text-align: center;
        margin-left: 10vw;
        margin-top: 30vh;
        }
        .ourTextone {
            font-size: 3.1vw;
            width: 78vw;
            margin-left: 5vw;
            margin-bottom: 10vh;
        }
        .ourImages {
            height: 45vh;
            margin-left: 25vw;
            margin-top: -2vw;
        }
        .ourfont {
            font-size: 4vw;
            margin-left: 5vw;
        }
        .descText p {
            font-size: 3.28vw;
            margin-left: 8vh;
            max-width: 97.79vh;
        }
        .paraDescription {
            width: 65vw;
            text-align: left;
            margin-top: -2vw;
        }
        .strongDescription {
            font-size: 3.5vw;
        }
}
@media (min-width:769px) and (max-width:991px) {
        .ourText {
        color: var(--mid-Green);
        font-weight: 500;
        font-size: 3.5vw;
        font-family: "Chobani-Serif-Web-Semibold-Regular";
        text-align: center;
        margin-left: 7vw;
        margin-top: 30vh;
        }
        .ourTextone {
            font-size: 2.8vw;
            width: 72vw;
            margin-left: 3.5vw;
            margin-bottom: 10vh;
        }
        .ourImages {
            height: 50vh;
            margin-left: 30vw;
            margin-top: -2vw;
        }
        .ourfont {
            font-size: 4vw;
            margin-left: 5vw;
        }
        .descText p {
            font-size: 2.5vw;
            margin-left: 20vh;
            width: 55vw;
            margin-top: 3vh;
        }
        .paraDescription {
            width: 70vw;
            text-align: left;
            margin-left: 10vw;
            margin-top: -2vw;
        }
        .strongDescription {
            font-size: 2.9vw;
        }
}
