@use "../../../Styles/mixins.scss" as mixins;
.Alignment{
    /* display: flex; */
    justify-content: center;
    text-align: center;
    margin-top: 8.2vh;
    
}
.Alignment h2{
    color: var(--mid-Green);
    font-weight: 500;
    font-size: 2vw;
    font-family: "Chobani-Serif-Web-Semibold-Regular";
}
.AppleImg{ 
    position: relative;
    z-index: -1;
    height: 68vh;
}

.waveUp {
    height: 65.6vh;
    margin-top: -20vh;
}

.waveDown {
    @include mixins.wave-style-down;
    position: relative;
    height: 10vh;
    z-index: -999;
}

.story-body-1{
    color:var(--mid-Green); 
    margin-top: -61vh;  
}

.story-body-1 p{
    font-size: 1.1vw;
    font-family: "Proxima-Nova-Regular";

}

.para1{     
    width: 50.7vw;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 20.8vw;
    margin: 0 auto;
}

.para2{
    width: 50.7vw;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 20.8vw;
    margin: 0 auto;
    margin-top: 6.8vh;
    margin-bottom: 4.7vh;
}
.para4 {
    width: 45.7vw;
    margin-top: 2vw;
    margin-left: 25.8vw;
    z-index: 999;
}

.story-body-1 .para3{
    // font-family: "Proxima-semi-Bold";
    font-size:1.56vw;
    font-weight: 500;
}

@media (min-width:320px) and (max-width:480px) {
    .Alignment h2{
        color: var(--mid-Green);
        font-weight: 500;
        font-size: 8vw;
        // font-family: "Chobani-Serif-Web-Semibold-Regular";
        text-align: center;
        margin-left: 7vw;
    }
    .AppleImg{ 
        position: relative;
        z-index: -1;
        height: 70vh;
    }
    .story-body-1 .para1 {
        font-size: 3vw;
        width: 76vw;
    }
    .story-body-1 .para2 {
        font-size: 3vw;
        width: 76vw;
    }
    .story-body-1 .para3 {
        font-size: 6vw;
        font-weight: 500;
        margin-bottom: 7vw;
    }
    .story-body-1 .para4{
        font-size: 3vw;
        width: 80vw;
        text-align: center;
        margin-left: 11vw;
    }
    .story-body-1 {
        margin-bottom: -10vh;
        margin-top: -53vh;
    }
    .waveUp {
        height: 50vh;
        margin-top: -20vh;
    }
    
    .waveDown {
        @include mixins.wave-style-down;
        position: relative;
        height: 10vh;
        z-index: -999;
    }
}
@media (min-width:481px) and (max-width:576px) {
    .Alignment h2{
        color: var(--mid-Green);
        font-weight: 500;
        font-size: 8vw;
        font-family: "Chobani-Serif-Web-Semibold-Regular";
        text-align: center;
        margin-left: 7vw;
    }
    .AppleImg{ 
        position: relative;
        z-index: -1;
        height: 70vh;
    }
    .story-body-1 .para1 {
        font-size: 3vw;
        width: 76vw;
        margin-left: 10vh;
    }
    .story-body-1 .para2 {
        font-size: 3vw;
        width: 76vw;
        margin-left: 10vh;
    }
    .story-body-1 .para3 {
        font-size: 6vw;
        font-weight: 400;
        margin-bottom: 7vw;
        margin-left: 6vh;
    }
    .story-body-1 .para4 {
        font-size: 3vw;
        width: 80vw;
        text-align: center;
        margin-left: 11vw;
        z-index: 1000;
    }
    .story-body-1 {
        margin-bottom: -10vh;
        margin-top: -78vh;
    }
    .waveDown {
       @include mixins.wave-style-down;
       position: relative;
       height: 25vh;
       z-index: -999;
    }
}
@media (min-width:577px) and (max-width:768px) {
    .Alignment h2{
        color: var(--mid-Green);
        font-weight: 500;
        font-size: 6.5vw;
        font-family: "Chobani-Serif-Web-Semibold-Regular";
        text-align: center;
        margin-left: 7vw;
        z-index: 999;
    }
    .AppleImg{ 
        position: relative;
        z-index: -1;
        height: 70vh;
    }
    .story-body-1 .para1 {
        font-size: 3vw;
        width: 76vw;
    }
    .story-body-1 .para2 {
        font-size: 3vw;
        width: 76vw;
    }
    .story-body-1 .para3 {
        font-size: 6vw;
        font-weight: 400;
        margin-bottom: 7vw;
        margin-left: 6vh;
    }
    .story-body-1 .para4{
        font-size: 3vw;
        width: 80vw;
        text-align: center;
        margin-left: 11vw;
        z-index: 999;
    }
    .story-body-1 {
        margin-bottom: -10vh;
        margin-top: -85vh;
    }
    .waveUp {
        height: 75vh;
        // margin-top: -20vh;
    }
    .waveDown {
       @include mixins.wave-style-down;
       position: relative;
       height: 20vh;
       z-index: -999;
    }
}
@media (min-width:769px) and (max-width: 991px) {
    .Alignment h2{
        color: var(--mid-Green);
        font-weight: 500;
        font-size: 6.5vw;
        font-family: "Chobani-Serif-Web-Semibold-Regular";
        text-align: center;
        margin-left: 7vw;
        z-index: 999;
    }
    .AppleImg{ 
        position: relative;
        z-index: -1;
        height: 70vh;
    }
    .story-body-1 .para1 {
        font-size: 3vw;
        width: 76vw;
    }
    .story-body-1 .para2 {
        font-size: 3vw;
        width: 76vw;
    }
    .story-body-1 .para3 {
        font-size: 4vw;
        font-weight: 500;
        margin-bottom: 7vw;
        margin-left: 5vw;
    }
    .story-body-1 .para4{
        font-size: 3vw;
        width: 80vw;
        text-align: center;
        margin-left: 11vw;
        z-index: 999;
    }
    .story-body-1 {
        margin-bottom: -10vh;
        margin-top: -105vh;
    }
    .waveUp {
        height: 100vh;
        // margin-top: -20vh;
    }
    .waveDown {
        @include mixins.wave-style-down;
        position: relative;
        height: 20vh;
        z-index: -999;
     }
}



