.head-1 {
    text-align: center;
    color: var(--light-Green);
    font-family: Chobani-Serif-Web-Semibold-Regular;
    margin-bottom: 7px;
    margin-top: 20px;
    font-size: 3.5vw;
}

.head-2 {
    text-align: center;
    color: var(--light-Green);
    font-size: 20px;
    margin-bottom: -3.5vh;
    font-size: 2.5vw;
}

@media (min-width:320px) and (max-width:480px) {
    .head-1 {
        font-size: 8vw;
    }
        
    .head-2 {
        font-size: 6vw;
        font-weight: 200;
        margin-bottom: -10vh;
    }
}

@media (min-width:481px) and (max-width:576px) {

    .head-1 {
        font-size: 6.5vw;
    }
            
    .head-2 {
        font-size: 4.5vw;
        font-weight: 200;
        margin-bottom: -7.5vh;
    }
}

@media (min-width:577px) and (max-width:768px) {

    .head-1 {
        font-size: 6vw;
    }
                        
    .head-2 {
        font-size: 4vw;
        font-weight: 200;
        margin-bottom: -10vh;
    }
}