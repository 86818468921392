// @import "../../../Styles/mixins.scss";
// @import "../../../Styles/variables.scss";
@use "../../../Styles/variables.scss" as vars;
@use "../../../Styles/mixins.scss" as mixins;

.parent {
  position: relative;
  margin-block: 14vh 9vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.paragraph {
  width: 64%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--light-Green);
  font-family: "FontsFree-Net-Proxima-Nova-semibold";
  font-size: 1.2vw;
  margin-top: 3.5vh;
}

.para {
  font-weight: bolder ;
  margin-top: -1.9vh;
}

.images-container1,
.images-container2,
.images-container3 {
  position: absolute;
  // top: -2.5vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: -12;
}

.images-container2 {
  
  margin-left: 50vw;
}

.images-container3 {
  
  margin-left: 65vw;
  
}

.image {
  width: 4.8vw;
  height: 14vh;
  margin-right: 60vw;

}

.waveDown{
  @include mixins.wave-style-down;
  height: 25vh;
margin-top:-9vh;
position: relative;
}

// .secondWave {
//   @include wave( $height: 25vh, $transform: rotate(180deg));
//   display: flex;
//   width: 100%;
//   margin-top: -9vh;
 
// }



@media (max-width: 768px) {
  .paragraph {
    font-size: 1.8vw;
    margin-top: 3vh;
  }

  .para {
    margin-top: -1.9vh;
  }

  .image {
    width: 9vw;
    height: 12vh;
    margin-right: 48vw;
    margin-top: 0.6vh;
  }

  .images-container2 {
    margin-left: 47vw;
    margin-top: -0.5vh;
  }

  .images-container3 {
    margin-left: 60vw;
  }
  .images-container1{
    position: absolute;
    top: 0vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: -12;
  }

  .secondWave {
    display: flex;
    height: 5vh;
    bottom: 1;
    width: 100%;
    transform: rotate(180deg);
  }
}

@media (max-width: 576px) {
  .paragraph {
    font-size: 2vw;
    margin-top: 3vh !important;
  }
  .para {
    margin-top: -0.8vh;
  }


  .image {
    width: 8vw;
    height: 10vh;
    margin-right: 50vw;
    margin-top: 2vh;
  }

  .images-container2 {
    margin-left: 48vw;
  }

  .images-container3 {
    margin-left: 61vw;
  }

  .paragraph {
    width: 87%;
  }
  
  .secondWave {
    display: flex;
    height: 8vh;
    bottom: 1;
    width: 100%;
    transform: rotate(180deg);
  }
}

@media (max-width: 480px) {
  .paragraph {
    font-size: 2.3vw !important;
    margin-top: 3vh !important;
    margin-left: 3vw !important
  }
  .para {
    margin-top: -1.9vh;
  }

  .image {
    width: 9vw;
    height: 10vh;
    margin-right: 62vw;
    margin-top: 1.3vh;
  }

  .images-container2 {
    margin-left: 56vw;
    margin-top: -0.1vh;
  }

  .images-container3 {
    margin-left: 70vw;
    margin-top: 0.4vh;
  }

  .secondWave {
    display: flex;
    height: 5vh;
    bottom: 0;
    width: 100%;
    transform: rotate(180deg);
  }
  
}

@media (max-width: 320px) {
  .paragraph {
    font-size: 2.4vw !important;
    margin-top: 2vh !important;
  }
  .para {
    margin-top: -0.9vh;
  }

  .image {
    margin-right: 61vw;
    margin-top: 0.5vh;
    width: 10vw;
    height: 11vh;
  }

  .images-container2 {
    margin-left: 58vw;
    margin-top: 0.2vh;
  }

  .images-container3 {
    margin-left: 72vw;
  
    
  }
  .secondWave {
    display: flex;
    height: 5vh;
    width: 100%;
    transform: rotate(180deg);
    
  }
  

}


