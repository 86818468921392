@use '../../../Styles/variables.scss' as vars;
 
//  New code for responsivness
.footerMain {
  background-color: var(--light-Green);
  color: var(--white-color);
  height: 76vh;
}

.firstmaindiv {
  display: flex;
  justify-content: space-around;
  margin-top: 9.5vh;
}

.firstdiv img {
  width: 16.2vw;
  margin-top: 5.4vh;
  margin-left: 5.2vw;
  margin-right: 5.2vw;
}

.firstsecond p {
  margin-bottom: 5px;
}

.secondmaindiv {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 70px;
}

.secondfirst p {
  margin-bottom: 5px;
}

.nav_Links {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.footer_Links{
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.thirdmaindiv {
  display: flex;
  justify-content: space-around;
  margin-bottom: -120px;
  color: white;
  margin-top: -1%;
}

.thirdfirstdiv {
  display: flex;
  width: 29vw;
  justify-content: space-around;
  align-items: center;
  z-index: 89;
  padding-bottom: -50%;
  font-size: 1vw;
}

.para3 {
  font-size: 1vw;
}

.thirdfirstdiv p {
  margin-bottom: 0px;
}

.thirdseconddiv {
  width: 330px;
  z-index: 89;
  padding-bottom: -50px;
}

.thirdseconddiv p {
  margin-bottom: 0px;
}

.seconddiv input {
  border-radius: 4px;
  padding: 0 4px;
  border: none;
  outline: none;
}

.banner {
  width: 13vw;
  height: 13.6vh;
  position: absolute;
  right: 15%;
  z-index: 1;
}

#column1Header {
  font-size: 2vw;
  font-weight: 600;
  cursor: pointer;
}

.firstsecond li {
  font-weight: 400;
}

.secondfirst li {
  font-weight: 400;
}

li {
  font-size: 1.6vw;
}
@media (max-width: 320px) {

  .footerMain {
    background-color: var(--light-Green);
    color: var(--white-color);
    z-index: 999;
    height: 80vh;
  }

  #column1Header {
    font-size: 3vh;
  }

  .firstsecond li {
    font-size: 2vh;
  }

  .secondfirst li {
    font-weight: 400;
  }

  .footerMain p {
    font-size: 2vh;
  }

  .firstmaindiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 3%;
    margin-top: 20px;
    align-items: center;
  }

  .firstdiv img {
    width: 40vw;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  /* .firstsecond
  {
    margin-left: 30px;
  } */

  .firstsecond p {
    margin-bottom: 5px;
  }

  .secondmaindiv {
    width: 40%;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-left: 0px;
    margin-bottom: 10vh;
    margin-top: 20px;
  }

  .secondfirst
  {
    margin-bottom: 11vh;
  }

  .secondfirst p {
    margin-bottom: 5px;
  }

  .nav_Links {
    text-decoration: none;
    color: white;
    cursor: pointer;
    font-size: 2vw;

  }

  .thirdmaindiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: -120px;
    color: white;
    font-size: x-small;
    text-align: center;
    align-items: center;

    background-color: var(--dark-Green);
  }

  .thirdfirstdiv {
    display: flex;
    width: 450px;
    justify-content: space-around;
    align-items: center;
    z-index: 89;
    padding-bottom: 2%;
    width: 70%;
    font-size: xx-small;
  }

  .thirdseconddivpara {
    width: 95vw;
    text-align: center;
    margin-top: 6px;
  }

  .thirdseconddiv {
    width: 330px;
    z-index: 89;
    padding-bottom: -50px;
  }

  .thirdseconddiv p {
    margin-bottom: 0px;
  }

  .seconddiv input {
    border-radius: 4px;
    padding: 0 4px;
    border: none;
    outline: none;
  }

  .banner {
    width: 200px;
    height: 100px;
    position: absolute;
    right: 15%;
    z-index: 1;
  }

  .wave_footer {
    height: 300px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {  
  .footerMain {
  background-color: var(--light-Green);
  color: var(--white-color);
  z-index: 999;
  height:70vh;
}
#column1Header{
  font-size: 2.4vh;
}
.firstsecond li{
  font-size: 1.5vh;
}
.secondfirst li{
  font-weight: 400;
}
.footerMain p{
  font-size: 2vh;
}
.firstmaindiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 3%;
  margin-top: 62px;
  align-items: center;
  gap: 42px;
}
.firstdiv img {
  width: 38vw;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

li{
  margin-bottom: 6;
}
.firstsecond p {
  margin-bottom: 5px;
}

.secondmaindiv {
  width: 38%;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-left: 0px;
  margin-bottom: 10vh;
  margin-top: 20px;
}

.secondfirst
{
  margin-bottom: 3.2vh;
}
.secondfirst p {
  margin-bottom: 5px;
}

.nav_Links {
    text-decoration: none;
    color: white;
    cursor: pointer;
    font-size:1.5vh;

}

.thirdmaindiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: -120px;
  color: white;
  font-size: x-small;
  text-align: center;
  align-items: center;
  background-color: var(--dark-Green);
}
.thirdfirstdiv {
  display: flex;
  width: 450px;
  justify-content: space-around;
  align-items: center;
  z-index: 89;
  padding-bottom: 2%;
  width: 70%;
  font-size: xx-small;
}
.thirdseconddivpara{
  width: 95vw;
  text-align: center;
  margin-top:6px;
}

.thirdseconddiv {
  width: 85vw;
  z-index: 89;
  padding-bottom: -50px; 
}
.thirdseconddiv p {
  font-size: 1.9vw;
  margin-top: 16px;
}

.seconddiv input {
  border-radius: 4px;
  padding: 0 4px;
  border: none;
  outline: none;
}

.footer_Links{
font-size: 1.4vh;
}
li{
margin-bottom: 5px;
}}
@media (min-width: 481px) and (max-width: 576px) {
  .footerMain {
    background-color: var(--light-Green);
    color: var(--white-color);
    z-index: 999;
    height:83vh;
  }
  #column1Header{
    font-size: 3.5vh;
  }
  .firstsecond li{
    font-size: 2.2vh;
  }

  .secondfirst li {
    font-weight: 400;
  }

  .footerMain p {
    font-size: 2vh;
  }

  .firstmaindiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
    margin-top: 62px;
    align-items: center;
    gap: 42px;
  }

  .firstdiv img {
    width: 38vw;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  li{
    margin-bottom: 6;
  }
  .firstsecond p {
    margin-bottom: 5px;
  }

  .secondmaindiv {
    width: 38%;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-left: 0px;
    margin-bottom: 10vh;
    margin-top: 20px;
  }
  
  .secondfirst
  {
    margin-bottom: 8vh;
  }

  .secondfirst p {
    margin-bottom: 5px;
  }

  .nav_Links {
      text-decoration: none;
      color: white;
      cursor: pointer;
      font-size:2.2vh;

  }

  .thirdmaindiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: -120px;
    color: white;
    font-size: x-small;
    text-align: center;
    align-items: center;
    background-color: var(--dark-Green);
  }
  .thirdfirstdiv {
    display: flex;
    width: 450px;
    justify-content: space-around;
    align-items: center;
    z-index: 89;
    padding-bottom: 2%;
    width: 70%;
    font-size: xx-small;
  }

  .thirdseconddivpara {
    width: 95vw;
    text-align: center;
    margin-top: 6px;
  }

  .thirdseconddiv {
    width: 85vw;
    z-index: 89;
    padding-bottom: -50px;
  }

  .thirdseconddiv p {
    font-size: 1.9vw;
    margin-top: 16px;
  }

  .seconddiv input {
    border-radius: 4px;
    padding: 0 4px;
    border: none;
    outline: none;
  }
  
  .footer_Links{
  font-size: 2vh;
}
li{
  margin-bottom: 5px;
}
}

@media (min-width:577px) and (max-width: 768px) {

  .footerMain {
    background-color: var(--light-Green);
    color: var(--white-color);
    z-index: 999;
    height:96vh;
  }
  #column1Header{
    font-size: 4.6vh;
  }
  .firstsecond li{
    font-size: 2.8vh;
  }

  .secondfirst li {
    font-weight: 400;
  }

  .footerMain p {
    font-size: 2vh;
  }

  .firstmaindiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
    margin-top: 62px;
    align-items: center;
    gap: 42px;
  }

  .firstdiv img {
    width: 39vw;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  li{
    margin-bottom: 6;
  }
  .firstsecond p {
    margin-bottom: 5px;
  }

  .secondmaindiv {
    width: 38%;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-left: 0px;
    margin-bottom: 10vh;
    margin-top: 20px;
  }
  
  .secondfirst
  {
    margin-bottom: 12vh;
  }

  .secondfirst p {
    margin-bottom: 5px;
  }

  .nav_Links {
      text-decoration: none;
      color: white;
      cursor: pointer;
      font-size: 2.8vh;

  }

  .thirdmaindiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: -120px;
    color: white;
    font-size: x-small;
    text-align: center;
    align-items: center;
    background-color: var(--dark-Green);
  }

  .thirdfirstdiv {
    display: flex;
    width: 450px;
    justify-content: space-around;
    align-items: center;
    z-index: 89;
    padding-bottom: 2%;
    width: 70%;
    font-size: xx-small;
  }

  .thirdseconddivpara {
    width: 95vw;
    text-align: center;
    margin-top: 6px;
  }

  .thirdseconddiv {
    width: 85vw;
    z-index: 89;
    padding-bottom: -50px;
  }

  .thirdseconddiv p {
    font-size: 1.9vw;
    margin-top: 16px;
  }

  .seconddiv input {
    border-radius: 4px;
    padding: 0 4px;
    border: none;
    outline: none;
  }
  
  .footer_Links{
  font-size: 2.5vh;
}
li{
  margin-bottom: 5px;
}
}
@media (min-width:769px) and (max-width: 992px) {

  .footerMain {
    background-color: var(--light-Green);
    color: var(--white-color);
    z-index: 999;
    height:96vh;
  }
  #column1Header{
    font-size: 5.9vh;
  }
  .firstsecond li{
    font-size: 3vh;
  }

  .secondfirst li {
    font-weight: 400;
  }

  .footerMain p {
    font-size: 2vh;
  }

  .firstmaindiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
    margin-top: 62px;
    align-items: center;
    gap: 42px;
  }

  .firstdiv img {
    width: 30vw;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  li{
    margin-bottom: 6;
  }
  .firstsecond p {
    margin-bottom: 5px;
  }

  .secondmaindiv {
    width: 35%;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-left: 0px;
    margin-bottom: 10vh;
    margin-top: 20px;
  }
  
  .secondfirst
  {
    margin-bottom: 14vh;
  }

  .secondfirst p {
    margin-bottom: 5px;
  }

  .nav_Links {
      text-decoration: none;
      color: white;
      cursor: pointer;
      font-size: 3vh;

  }

  .thirdmaindiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: -120px;
    color: white;
    font-size: x-small;
    text-align: center;
    align-items: center;
    background-color: var(--dark-Green);
  }

  .thirdfirstdiv {
    display: flex;
    width: 450px;
    justify-content: space-around;
    align-items: center;
    z-index: 89;
    padding-bottom: 2%;
    width: 70%;
    font-size: xx-small;
  }

  .thirdseconddivpara {
    width: 95vw;
    text-align: center;
    margin-top: 6px;
  }

  .thirdseconddiv {
    width: 85vw;
    z-index: 89;
    padding-bottom: -50px;
  }

  .thirdseconddiv p {
    font-size: 1.9vw;
    margin-top: 16px;
  }

  .seconddiv input {
    border-radius: 4px;
    padding: 0 4px;
    border: none;
    outline: none;
  }
  
  .footer_Links{
  font-size: 2.5vh;
}
li{
  margin-bottom: 5px;
}
}
@media (min-width:992px) {

.footerMain {
  background-color: var(--light-Green);
  color: var(--white-color);
  /* z-index: 999; */
  height: 73vh;
}

.firstmaindiv {
  /* width: 100%; */
  display: flex;
  justify-content: space-around;
  /* margin-bottom: 3%; */
  margin-top: 9.5vh;
}

.commondiv {
  margin-top: 20px;
}

.firstdiv img {
  width: 22.3vw;
  margin-top: 9vh;
  /* margin-left: 5.2vw; */
  margin-right: 5.2vw;
}
/* .firstsecond
{
  margin-left: 30px;
} */

.firstsecond p {
  margin-bottom: 5px;
}

.secondmaindiv {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 9.5vh;
  margin-right: 67px;
}

.secondfirst p {
  margin-bottom: 5px;
}

.nav_Links {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.thirdmaindiv {
  display: flex;
  justify-content: space-around;
  margin-bottom: -17vh;
  color: white;
  margin-top: -1%;
  font-size: 1.6vw;
  /* margin-top: 50px; */
  /* background-color: var(--dark-Green); */
}

.thirdfirstdiv {
  display: flex;
  width: 35vw;
  justify-content: space-around;
  align-items: center;
  z-index: 89;
  padding-bottom: -50%;
  font-size: 1.3vw;
}

.thirdfirstdiv p {
  margin-bottom: 0px;
}

.thirdseconddiv {
  width: 28vw;
  z-index: 89;
  padding-bottom: -6.8vh;
}

.thirdseconddiv p {
  margin-bottom: 0px;
  font-size: 1.3vw;
}

.seconddiv input {
  border-radius: 4px;
  padding: 0 4px;
  border: none;
  outline: none;
}

.banner {
  width: 13vw;
  height: 13.6vh;
  position: absolute;
  right: 15%;
  z-index: 1; 
}
#column1Header{
  font-size: 2vw !important;
  font-weight:600;
  cursor: pointer;
}
.firstsecond li{
  font-weight: 400;
}
.secondfirst li{
  font-weight: 400;
}
}