.blog-story-image {
    width: 18.5vw;
    height: 35vh;
    border-radius: 1vw;
    object-fit: cover;
}
.blog-story
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.image-heading {
    color: var(--mid-Green);
    font-family: 'Chobani-Serif-Web-Semibold-Regular';
    cursor: pointer;
    width: max-content;
    font-size: 2vw;
    padding: 2px 2px;
    margin-top: 2vh;
    transition: all 0.3s;
}

.image-heading:hover {
    background-color: var(--mid-Green);
    color: var(--white-color);
    padding: 2px 2px;
    border-radius: 5px;
}

.blog-story-main {
    margin-top: 8.2vh;
    margin-left: 9.1vw;
    margin-right: 9.1vw;
}

.ButtonMore {
    background-color: var(--light-color);
    font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
    width: 9.7vw;
    padding: 10px;
    border: none;
    border-radius: 0.5vw;
    margin-top:15vh;
    font-size: 1vw;
}
@media (max-width:320px)  {
    .blog-story-image {
        width: 35vw;
        height: 18vh;
        border-radius: 10px;
        object-fit: cover;
    }

    .blog-story-main {
        margin-top: 60px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .image-heading {
        font-size: 3vh;
    }
    .ButtonMore {
        background-color: var(--light-color);
        font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
        width: 9.7vw;
        padding: 10px;
        border: none;
        border-radius: 0.5vw;
        margin-top:3vh;
        font-size: 1vw;
    }
}
@media (min-width:320px) and (max-width:480px) {
    .blog-story-image {
        width: 35vw;
        height: 18vh;
        border-radius: 10px;
        object-fit: cover;
    }

    .blog-story-main {
        margin-top: 60px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .image-heading {
        font-size: 3vh;
    }
    .ButtonMore {
        background-color: var(--light-color);
        font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
        width: 23.7vw;
        padding: 10px;
        border: none;
        border-radius: 1.5vw;

        margin-top: 3vh;
        font-size: 3vw;
    }
}

@media (min-width:480px) and (max-width:576px) {
    .blog-story-image {
        width: 31vw;
        height: 20vh;
        border-radius: 10px;
        object-fit: cover;
    }

    .blog-story-main {
        margin-top: 60px;
        margin-left: 0px;
        margin-right: 0px
    }

    .image-heading {
        font-size: 3vh;
    }
    .ButtonMore {
        background-color: var(--light-color);
        font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
        width: 20.7vw;
        padding: 10px;
        border: none;
        border-radius: 1.5vw;
        margin-top: 3vh;
        font-size: 2vw;
    }
}
@media (min-width:577px) and (max-width:768px) {
    .blog-story-image {
        width: 35vw;
        height: 28vh;
        border-radius: 10px;
        object-fit: cover;
    }

    .blog-story-main {
        margin-top: 60px;
        margin-left: 0px;
        margin-right: 0px
    }

    .image-heading {
        font-size: 3vh;
    }
    .ButtonMore {
        background-color: var(--light-color);
        font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
        width: 20.7vw;
        padding: 10px;
        border: none;
        border-radius: 1.5vw;
      
      
        margin-top: 3vh;
        font-size: 2vw;
    }
}
@media (min-width:768px) and (max-width:992px) {
    .blog-story-image {
        width: 26vw;
        height: 28vh;
        border-radius: 10px;
        object-fit: cover;
    }

    .blog-story-main {
        margin-top: 60px;
        margin-left: 0px;
        margin-right: 0px
    }

    .image-heading {
        font-size: 3vh;
    }
    .ButtonMore {
        background-color: var(--light-color);
        font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
        width: 17vw;
        padding: 10px;
        border: none;
        border-radius: 1.5vw;

        margin-top: 3vh;
        font-size: 2vw;
    }
}