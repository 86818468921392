.image-bottle {
    position: relative;
    width: 9vw;
    height: 52vh;
    object-fit: cover;
    transition: transform 0.1s ease;
    overflow: hidden;
}

.subdiv:hover .image-bottle {
    transform: translateY(-10%);
    overflow: hidden;
    -webkit-animation: slide-top .5s cubic-bezier(.25, .46, .45, .94) both;
    animation: slide-top .5s cubic-bezier(.25, .46, .45, .94) both
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-50px)
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px)
    }
}

.bottle-heading {
    color: var(--mid-Green);
    font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
    margin-bottom: 50px;
    border: none;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    text-decoration: none;
    /* margin-bottom: -10vh; */
    font-size: 1.3vw;
    margin-top: 5vh;
}

.bottle-heading {
    display: block;
    background: transparent;
    padding: 0.625rem 0.325rem;
    transition: var(--mid-Green);
    font-weight: bold;
}

.image-headingtop:hover .image-bottle {
    -webkit-animation: slide-top .5s cubic-bezier(.25, .46, .45, .94) both;
    animation: slide-top .5s cubic-bezier(.25, .46, .45, .94) both
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-50px)
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px)
    }
}

.image-headingtop:hover {
    /* text-decoration: underline; */
    color: var(--mid-Green);
}

.main-div {
    margin-top: 10vh;
    padding: 0 10vh;
    margin-bottom: -5vh;
   
}

.Bowl-image-styles {
    position: relative;
    /* margin-top: -2vh; */
    width: 15vw;
    height: 30vh;
}

@media (min-width:320px) and (max-width:480px) {
    .image-bottle {
        position: relative;
    width: 13vw;
        height: 28vh;
        object-fit: cover;
        transition: transform 0.1s ease;
        overflow: hidden;
       
    }
    .bottle-heading {
        font-size: 2.80vw;
    }
    .Bowl-image-styles {
            width: 28vw;
                height: 21vh;
    }
   
}
@media (min-width:481px) and (max-width:576px) {
    .image-bottle {
        position: relative;
        width: 13vw;
            height: 34vh;
        object-fit: cover;
        transition: transform 0.1s ease;
        overflow: hidden;
    }
   
    .bottle-heading{
        font-size: 2.7vw;
    }
    
        .Bowl-image-styles {

        width: 26vw;
            height: 22vh;
    }
   
}
@media (min-width:577px) and (max-width:768px) {
    .image-bottle {
        position: relative;
    width: 13vw;
        height: 40vh;
        object-fit: cover;
        transition: transform 0.1s ease;
        overflow: hidden;
    }
   
    .bottle-heading{
        font-size: 2.8vw;
        margin-bottom: 10vh;
    }
    
        .Bowl-image-styles {

        width: 26vw;
        height: 25vh;
    }
}
@media (min-width:769px) and (max-width:992px) {
    .image-bottle {
        position: relative;
        width: 14vw;
            height: 55vh;
        object-fit: cover;
        transition: transform 0.1s ease;
        overflow: hidden;
    }
    .bottle-heading{
        font-size: 2.3vw;
    }
  
    .Bowl-image-styles {
        width: 24vw;
            height: 33vh;
    }
}

