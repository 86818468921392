.BannerImg{
    width:30vw;
    height:55vh;
    margin: 7%; 
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3vh;
    margin-top: 5vh;
}
.para{
  
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 55vw;
    margin-top: 0.5vh;
    margin-bottom: 5vh;
    color: var(--light-Green);
    font-size: 1vw;
    
}
.rectangle{
  height: 44vh;
  width: 55vw;
  border-radius: 0.5vw;
  background-color:var(--wave-fill-Green);
 
  margin-bottom: 5vh;
  position: relative;
}
.Size{
    box-sizing: border-box;
    color: var(--mid-Green); 
    font-size: 2.7vw;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 52vh;
    margin-top: 18vb;
    position: absolute;
 
}

@media  (min-width:320px) and (max-width:480px){

    .BannerImg{
        width:49vw;
        height:25vh;
        margin: 7%; 
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 7vh;
        margin-top: 5vh;
    }
    .rectangle{
        height:23vh ;
        width:75vw ;
        border-radius: 0.5vw;
        background-color: var(--wave-fill-Green);
        margin-bottom: -1vh;
       /* padding-right: 5px;
       padding-left: 5px; */
      }
      .Size{
        color: var(--mid-Green); 
        height: 5vh;
        width: 6vw;
        margin-top:7vb;
       margin-left:17vh;
       
    
    }    

      .para{
  
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 60vw;
        margin-top: -7vh;
        color: var(--light-Green);
        font-size: 3vw;
        text-align: justify;

        
    }
}

@media (min-width:481px) and (max-width:576px)
{
    .BannerImg{
        width:50vw;
        height:39vh;
        margin: 7%; 
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 7vh;
        margin-top: 5vh;
    }
   .rectangle{
        height: 34vh;
        width:84vw;
        border-radius: 1vw;
        background-color: var(--wave-fill-Green);
       /* padding-right: 5px; */
       /* padding-left: 5px; */
       /* margin-bottom: 15px; */
     
        
      }
      .Size{
          color: var(--mid-Green); 
          height: 6vh;
          width: 6vw;
         /* margin-bottom: 100px; */
      margin-left: 40vw;
      margin-top: 12vh;
     
      }   

      .para{
  
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 68vw;
        margin-top: -7vh;
        color: var(--light-Green);
        font-size: 3vw;
        text-align: justify;
        
    }
}

@media(min-width:577px) and (max-width:768px)
{
    .BannerImg{
        width:43vw;
        height:45vh;
        margin: 7%; 
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8vh;
        margin-top: 7vh;
        justify-content: center;
    }
   .rectangle{
        height:40vh ;
        width:83vw ;
        border-radius: 1vw;
        background-color: var(--wave-fill-Green);
       padding-right: 5vw;
       padding-left: 1vw;
        
      }
      .Size{
          color: var(--mid-Green); 
          font-size: 6.3vh;
          margin-left:35vw;
          margin-top: 14vh;

      }   

      .para{
  
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 67vw;
        margin-top: -9vh;
        color: var(--light-Green);
        font-size: 2.5vw;
        text-align: justify;
        
    }
}
@media (min-width:769px) and (max-width:992px)
{
    .BannerImg{
        width:359px;
        height:350px;
        margin: 7%; 
        display: block;
        margin-left: 40vb;
        margin-right: auto;
        margin-bottom: 50px;
        margin-top: 60px;
    }
   .rectangle{
        height: 45vh;
        width: 70vw;
        border-radius: 9px;
        background-color: var(--wave-fill-Green);
       padding-right: 5px;
       padding-left: 5px;
        
      }
      .Size{
          color: var(--mid-Green); 
          height: 43px;
          width: 43px;
          margin-bottom:0px;
          margin-left: 40vh;
          margin-top: 15vh;
      }   

      .para{
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 60vw;
        margin-top: -72px;
        color: var(--light-Green);
        font-size: 2.7vh;
        text-align: justify;
    }
}
