.navbar {
    background-color: #f0f0f0; 
    border-bottom: 1px solid #ccc; 
    padding: 20px 880px; 
    display: flex;
    justify-content: flex; 
    
  }
  
  .navItem {
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding: 5px 10px; 
    font-weight: 500;
  }
  
  .icon {
    width: 25px; 
    height: 26px; 
    margin-right: 0px;
  }

 
  .emailSection {
    width: 100%;
    max-width: 2200px;
    margin: auto;
    padding: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .emailHeader {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .inputField {
    flex: 1;
    margin-right: 10px;
  }
  
  .inputField label {
    display: block;
    margin-bottom: 5px;
  }
  
  .inputField input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #5b930b ;
    border-radius: 5px;
  }
  
  .toolbar {
    margin-bottom: 20px;
  }
  
  .messageBox {
    min-height: 200px;
    padding: 10px;
    border: 1px solid #5b930b;
    border-radius: 5px;
    background-color: white;
  }
  
  .sendButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #5b930b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .sendIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  
  .sendButton:hover {
    background-color: #5b930b;
  }
  

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .toolbar button,
  .toolbar select,
  .toolbar input[type="color"] {
    margin-right: 10px;
    padding: 5px;
    border: none;
    border-radius: 3px;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .toolbar select {
    padding: 3px;
  }
  