.body-content-2 {
    margin-bottom: 10vh;
}

.body-content-2-main {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: -1vh;

}

.body-content-2-main .corouselstyles {
    width: 100vw;
    // height: 90vh;
    margin-top: -110px;
    margin-bottom: 10px;
    justify-content: center;
}

.fade-in {
    animation: fadeIn 1s forwards;
    text-align: center;
        color: var(--mid-Green);
        // margin-bottom: 50px;
        font-size: 1.7vw;
}

.fade-out {
    animation: fadeOut 1s forwards;
    text-align: center;
        color: var(--mid-Green);
        // margin-bottom: 50px;
        font-size: 1.7vw;

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.4
    }
}

.corouselText p{
    text-align: center;
    color: var(--mid-Green);
    margin-top: -8vh;
    font-size: 1.8vw;
}

.button-Next {
    width: 6vw;
    margin-bottom: 22vh;
}

.button-Previous {
    width: 14vw;
    margin-bottom: 22vh;
}

@media (min-width:320px) and (max-width:480px) {

    
        .body-content-2 .button-Previous {
            width: 24vw;
            display: block;
        }
    .body-content-2 .button-Next {
            width: 10vw;
            display: block;
        } 
    .corouselText p {
        font-size: 2.5vw;
        margin-top: -3vh;
    }
                               
}

@media (min-width:481px) and (max-width:576px) {
    .corouselText p {
            font-size: 2.5vw;
            margin-top: -5vh;
        }
}

@media (min-width:577px) and (max-width:768px) {
     .corouselText p {
             font-size: 2.5vw;
            margin-top: -4vh;
         }
}