@use '../../../Styles/mixins.scss' as mixins;

.parent {
  position: relative;
  margin-block: 0px 90px;
}
.main_headings{
  height: 10vh;
  position: absolute;
  left: 45%;
  top: 12vh;
}
.heading1 {
  // position: absolute;
  // top: 28%;
  // left: 50%;
  // transform: translate(-50%, -50%); 
  font-size: 1.7vw;
  color: var(--light-Green);
  margin-bottom: 0px;
  margin-top: 0px;
}

.heading2 {
  font-size: 3vw;
  // top: 35%;
  // left: 52.3%;
  // transform: translate(-50%, -50%);
  // position: absolute;
  color: var(--mid-Green);
  margin-bottom: 0px;
  line-height: 0.6;
  font-family:Chobani-Serif-Web-Semibold-Regular ;
}

.paralorem {
  width: 45%;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:var(--light-Green);
  margin-top: 40px;
  font-size: 1.5vw;
}

.heading {
  color: var(--light-Green);
  /* background-color: var(--light-color); */
  font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
  width: max-content;
  border-radius: 10px;
  padding-top: 6px;
  padding-bottom: 10px;
  font-size: 1.8vw;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  z-index: 999;
  margin-bottom: 50px;
}

.waveUp{
  height:54.7vh;
}
.waveDown{
  @include mixins.wave-style-down;
  height:40px;
  bottom: -30px;
}

/* 
@media (max-width:1024px) {
  .heading1 {
    font-size: 23px;
    top: 17.5%;
  }

  .heading2 {
    font-size: 45px;
    left: 53.3%;
  }
  .paralorem{
    font-size: 16.5px;
  }
}

@media (max-width: 768px) {
 
  .heading1 {
    font-size: 22px;
  }

  .heading2 {
    font-size: 40px;
    top: 25.9%;
    left: 53%;
  }
  .paralorem {
    width: 80%;
  }
}

@media (max-width: 450px) {
 
  .heading1 {
    font-size: 21px;
  }

  .heading2 {
    font-size: 35px;
    left: 54%;
    top: 24.5%;
  }
} */
@media (min-width:320px) and (max-width:480px) {
  
  .main_headings{
    left: 32%;
    top: 14vh;
  }
  .heading1 {
    // position: absolute;
    // top: 130px;
    // left: 46%;
    // transform: translate(-50%, -50%);
    font-size: 5.6vw;
    color: var(--light-Green);
  }
  
  .heading2 {
    font-size: 10vw;
    // top: 45%;
    // left: 52.3%;
    // transform: translate(-50%, -50%);
    position: absolute;
    width: max-content;
    color: var(--mid-Green);
    font-family: Chobani-Serif-Web-Semibold-Regular;
  }
  
  .paralorem {
    width: 93%;
    text-align: center;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--light-Green);
    margin-top: 40px;
    font-size: 4vw;
  }
  .heading {
    color: var(--light-Green);
    /* background-color: var(--light-color); */
    font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
    width: max-content;
    border-radius: 10px;
    padding-top: 6px;
    padding-bottom: 10px;
    font-size: 3vw;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    z-index: 999;
    margin-bottom: 50px;
  } 

}

@media (min-width:480px) and (max-width:576px) {
  
  
  .heading1 {
    // position: absolute;
    // top: 100px;
    // left: 46%;
    // transform: translate(-50%, -50%);
    font-size: 5vw;
    color: var(--light-Green);
  }
  
  .heading2 {
    font-size: 9vw;
    // top: 38%;
    // left: 52.3%;
    // transform: translate(-50%, -50%);
    // position: absolute;
    width: max-content;
    color: var(--mid-Green);
    font-family: Chobani-Serif-Web-Semibold-Regular;
  }
  .main_headings{
    left: 32%;
    top: 14vh;
  }
  .paralorem {
    width: 70%;
    text-align: center;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--light-Green);
    margin-top: 40px;
    font-size: 3vw;
  }
  .heading {
    color: var(--light-Green);
    /* background-color: var(--light-color); */
    font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
    width: max-content;
    border-radius: 10px;
    padding-top: 6px;
    padding-bottom: 10px;
    font-size: 3vw;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    z-index: 999;
    margin-bottom: 50px;
  } 

}

@media (min-width:576px) and (max-width:768px) {
  
  .main_headings{
    left: 36%;
    top: 14vh;
  }
  .heading1 {
    // position: absolute;
    // top: 100px;
    // left: 46%;
    // transform: translate(-50%, -50%);
    
    font-size: 4vw;
    color: var(--light-Green);
  }
  
  .heading2 {
    font-size: 7vw;
    // top: 38%;
    // left: 50.3%;
    // transform: translate(-50%, -50%);
    // position: absolute;
    width: max-content;
    color: var(--mid-Green);
    font-family: Chobani-Serif-Web-Semibold-Regular;
  }
  
  .paralorem {
    width: 70%;
    text-align: center;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--light-Green);
    margin-top: 40px;
    font-size: 3vw;
  }
  .heading {
    color: var(--light-Green);
    /* background-color: var(--light-color); */
    font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
    width: max-content;
    border-radius: 10px;
    padding-top: 6px;
    padding-bottom: 10px;
    font-size: 3vw;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    z-index: 999;
    margin-bottom: 50px;
  } 

}
@media (min-width:768px) and (max-width:991px) {
  .main_headings{
    left: 37%;
    top: 14vh;
  } 
  
  .heading1 {
    // position: absolute;
    // top: 90px;
    // left: 46%;
    // transform: translate(-50%, -50%);
    font-size: 4vw;
    color: var(--light-Green);
  }
  
  .heading2 {
    font-size: 7vw;
    // top: 38%;
    // left: 50.3%;
    // transform: translate(-50%, -50%);
    // position: absolute;
    width: max-content;
    color: var(--mid-Green);
    font-family: Chobani-Serif-Web-Semibold-Regular;
  }
  
  .paralorem {
    width: 70%;
    text-align: center;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--light-Green);
    margin-top: 40px;
    font-size: 2vw;
  }
  .heading {
    color: var(--light-Green);
    /* background-color: var(--light-color); */
    font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
    width: max-content;
    border-radius: 10px;
    padding-top: 6px;
    padding-bottom: 10px;
    font-size: 3vw;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    z-index: 999;
    margin-bottom: 50px;
  } 

}
