.Nutri {
  align-items: center;
  justify-content: center;
  color: var(--dark-Green);
  margin-left: 5vw; 
  display: flex; 
  margin-top: 10vh;
}
.appleHand {
  margin-top: 7vh; 
  height: 40vh; 
}

.Nutri img {
  vertical-align: middle; 
  margin: 0; 
  padding: 0; 
}

.NutritionSide {
  margin-left: 10vw; 
}

.Nutri-1 {
  background-color: var(--light-color);
  width: 11vw;
  font-family: "Proxima-Nova-Alt-Bold";
  border-radius: 1vw; 
  margin-top: 3vh; 
  font-size: 1.1vw;
}
.Nutri-1:hover {
  background-color: var(--light-color);
}

.note {
  width: 100%;
  font-size: 1.2vw; 
  font-family: "Proxima-Nova-Regular";
  color: var(--mid-Green);
  font-weight: bold;
}

.Font-div2 {
  font-family: "Chobani-Serif-Web-Semibold-Regular";
  margin-top: -14vh; 
  font-size: 2vw; 
  font-weight: bold;
  color: var(--mid-Green);
}



/* For standard mobile devices (max-width: 576px) */
@media (max-width: 576px) {
  .Nutri {
    flex-direction: column;
    align-items: center;
    margin-left: -55vw !important;
  }
  .appleHand {
    height: 44vh !important; 
    margin-left: -28vw ; 
  }
  .note {
    width: 20% !important;
    font-size: 2.2vw !important;
    text-align: center;
    margin-left: 105vw !important;
    margin-top: -0.5vh
  }
  .NutritionSide {
    margin-left: 0;
    text-align: center;
  }
  .Font-div2 {
    margin-top: -42.2vh;
    font-size: 4vw !important;
    margin-left: 75vw !important;
  }
  .Nutri-1 {
    margin-top: 4.5vh !important;
    margin-right: -75vw !important;
    border-radius: 3vw;
  }
}

/* For small devices (max-width: 480px) */
@media (max-width: 480px) {
  .Nutri {
    flex-direction: column;
    align-items: center;
    margin-left: -65vw !important;
  }
  .appleHand {
    height: 40vh !important ; 
 
  }
  .note {
    width: 21% !important;
    font-size: 2.5vw !important;
    text-align: center;
    margin-left: 112vw !important
  }
  .NutritionSide {
    margin-left: 0;
    text-align: center;
  }
  .Font-div2 {
    margin-top: -38vh;
    margin-left: 78vw !important;
    font-size: 4.3vw !important;
  }
  .Nutri-1 {
    width: 22vw !important;
    font-size: 1.8vw !important;
    margin-right: -83vw !important;
    border-radius: 4vw !important;
    margin-top: 4vh !important;
  }
}

/* For extra small devices (max-width: 320px) */
@media (max-width: 320px) {
  .Nutri {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-left: -60vw !important;
    margin-top: -4vh;
  }
  .appleHand {
    height: 37vh !important; 
    margin-left: -48vw !important;

  }
  .note {
    width: 27% !important;
    font-size: 3vw !important;
    text-align: center;
    margin-left: 111vw !important;
  }
  .NutritionSide {
    margin-left: 0;
    text-align: center;
  }
  .Font-div2 {
    margin-top: -35.5vh;
    font-size: 6.5vw !important;
    margin-left: 94vw !important;
  }
  .Nutri-1 {
    width: 29vw !important;
    font-size: 2.5vw !important;
    margin-right: -93vw !important;
    border-radius: 3vw !important;
    margin-top: 4vh !important;
  }
}

/* For medium devices  (max-width: 768px) */
@media (max-width: 768px) {
  .Nutri {
    justify-content: space-around;
    margin-left: 3vw;
  }
  .appleHand {
    height: 44vh;
    margin-left: 10vw !important;
  }
  .note {
    width: 51%;
    font-size: 2vw;
    margin-left: 3vw
    
  }
  .NutritionSide {
    margin-left: 1vw; 
  }
  .Font-div2 {
    font-size: 3.6vw;
    margin-left: 3vw;
  }
  .Nutri-1 {
    width: 17vw;
    font-size: 1.8vw;
    margin-top: 4vh;
    border-radius: 2vw;
    margin-left: 2.4vw;
   }
 
  
}
