/* .ContactUs { 
    position: relative;
    width: 100%;

} */
/* 
.contact {

    font-family: "Chobani-Serif-Web-Semibold-Regular";
    color: var(--light-Green);
    margin-left: 40%;
    margin-top: 2rem;

}

.content {
    display: flex;
    margin-top: 10rem;
}  */

/* .mark {
    margin-left: 1rem;
} */
// @use '../../Styles/variables.scss' as vars;
@use '../../Styles/mixins.scss' as mixins;
.contact {
  font-family: "Chobani-Serif-Web-Semibold-Regular";
  color: var(--light-Green);
  text-align: center;
  margin-top: 2vh;
  font-size: 2vw;
}
p {
  margin-bottom: 0vh;
}
.watermelon {
  display: flex;
  /* flex-wrap: wrap;
  background-repeat: repeat; /* Tile the background image */
  /* background-size: auto; Adjust the size if needed  */
  align-items: center;

  justify-content: center;
  margin-left: 6vw;
  /* margin-right: 220px;
  margin-top: 90px;
  position: relative;  */
}

.ears {
  color: var(--light-Green);
  font-size: 1.4vw;
  font-weight: 600;
}

.buds {
  color: var(--light-Green);
  font-size: 1.1vw;
}

.reach_us1 {
  font-family: "FontsFree-Net-Proxima-Nova-Sbold";
  color: var(--light-Green);
  /* margin-left: 31rem; */
  /* margin-top: 3rem; */
  text-align: center;
  font-size: 1vw;

}

.img1 {
  /* width: 60px; */
  position: relative;
  height: 11.1vh;
  right: 8.8vw;
  top: 10.6vh;
  /* margin-top: 1rem; */
}

.waveDown {
  @include mixins.wave-style-down;
  height: 90px;
   bottom:-90px
}

.reach_us2 {
  font-family: "FontsFree-Net-Proxima-Nova-Sbold";
  color: var(--light-Green);
  /* margin-left: 31rem; */
  font-size: 1vw;
  text-align: center;
  margin-top: 16.15vh;
}

.img2 {
  /* width: 60px; */
  position: relative;
  height: 8.9vh;
  left: 8.9vw;
  /* top: 100px; */
  /* margin-top: 1rem; */
}

.note {
  font-family: "Proxima-Nova-Alt-Light";
  color: var(--light-Green);
  /* margin-left: 29rem; */
  text-align: center;
  width: 38.4vw;
  margin: 0 auto; /* Center horizontally */
  margin-top: 13.6vh;
  font-size: 1vw;
}

.parent {
  position: relative;
  margin-block: 12.9vh 9.7vw;
}



// @import "../../Styles/mixins";

// @import "../../Styles/variables.scss";
// :root {
//   --wave-fill:#DDE8CE;

// }

.parent {
  position: relative;
}

// .wave {
//   @include waveOptions(var(--wave-fill), 120px);
// }

// .waveInverted {
//   @include wave(var(--wave-fill), 90px, rotate(180deg), absolute, -80px, 0, 0);
// }






.paragraph {
  width: 56%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--light-Green);
  margin-top: 5.9vh;
  font-family: "FontsFree-Net-Proxima-Nova-Sbold";
  font-size: 1vw;
}

.forward {
  margin-left: 11.9vw;
}

.inlineImg1 {
  height: 8vh;
  margin-top: -1.5vh;
  margin-left: 1vw;
}

.inlineImg2 {
  height: 28vh;
  margin-left: 3.3vw;
  transform: rotate(10deg);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0);
}

// Media queries for responsiveness

// Extra small devices (portrait phones, less than 320px)
@media (max-width: 320px) {
  .contact {
    font-size: 6vw;
  }
  .ears, .buds, .reach_us1, .reach_us2, .note  {
    font-size: 2.8vw !important;
  }
  .paragraph{
    font-size: 2.8vw;
    margin-top: 4.6vh;
  }
  .watermelon {
  
    margin-right: -5vw ;
    text-align: center;
  }
  
  .img1 {
    height: 7.3vh;
    margin-left: -25vw !important;
    margin-bottom: 3.3vh;
   
  }
  .img2{
    height: 6vh;
    margin-left: 31vw !important;
  }
  
  .note {
    width: 90%;
  }
  .inlineImg1 {
    height: 8vh;
    margin-top: -1.5vh;
    margin-left: 1vw;
  }
  
  .inlineImg2 {
    height: 15.3vh;
    margin-left: 5.3vw;
    transform: rotate(10deg);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0);
  }
}

// Small devices (portrait phones, 320px and up)
@media  (max-width: 480px) {
  .contact {
    font-size: 5vw;
  }
  .ears, .buds, .reach_us1, .reach_us2, .note {
    font-size: 3.5vw;
  }
   .forward {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
  .watermelon{
    text-align: center;
    margin-left: 11vw !important;

  }
  .paragraph{
    font-size: 2.2vw !important;
    margin-top: 5vh;
  }
  .note {
    width: 85%;
  }
  .inlineImg1 {
    height: 4vh !important;
    margin-top: -0.5vh !important;
    margin-left: 1vw;
  }
  
  .inlineImg2 {
    height: 16.3vh;
    margin-left: 5.3vw;
    transform: rotate(10deg);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0);
  }
  .img1 {
    height: 8vh;
    margin-left: -26vw !important;
    margin-bottom: 3.3vh;
   
  }
  .img2{
    height: 7vh;
    margin-left: 31vw !important;
  }
}

// Standard mobile devices (landscape phones, 480px and up)
@media  (max-width: 576px) {
  .contact {
    font-size: 4vw;
  }
  .ears, .buds, .reach_us1, .reach_us2, .note {
    font-size: 3vw;
  }
   .forward {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
  .paragraph{
    font-size: 2vw !important;
    margin-top: 5.7vh;
  }
  .note {
    width: 80%;
  }
  .inlineImg1 {
    height: 5vh;
    margin-top: -1.5vh;
    margin-left: 1vw;
  }
  
  .inlineImg2 {
    height: 20.3vh;
    margin-left: 5.3vw;
    transform: rotate(10deg);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0);
  }
  .img1 {
    height: 8vh;
    margin-left: -26vw !important;
    margin-bottom: 3.3vh;
   
  }
  .img2{
    height: 7vh;
    margin-left: 31vw !important;
  }
  .watermelon{
    text-align: center;
    margin-left: 13vw !important;

  }
}

// Medium devices (tablets, 576px and up)
@media  (max-width: 768px) {
  .contact {
    font-size: 3.5vw;
  }
  .ears, .buds, .reach_us1, .reach_us2, .note, .paragraph {
    font-size: 2vw;
  }
  .forward {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
  .note {
    width: 70%;
  }
  .watermelon{
    text-align: center;
    margin-left: 18vw !important;

  }
  .paragraph{
    font-size: 1.8vw !important;
    margin-top: 4.9vh;
  }
  .img1 {
    height: 9.3vh;
    margin-left: -16vw !important;
    margin-bottom: 2.3vh;
   
  }
  .img2{
    height: 7.5vh;
    margin-left: 19vw !important;
  }
  .inlineImg1 {
    height: 7.2vh;
    margin-top: -1.5vh;
    margin-left: 1vw;
  }
  
}



