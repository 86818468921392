@use '../../../Styles/mixins.scss' as mixins;

.story-body-1{
    position: relative;
}
.story-body-1-main{
    width: 33vw;
    color: var(--light-Green);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}
.para1{
    text-align: left;
    width: 30vw;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.2vw;
    margin-top: 6.8vh;
}
.para2{
    text-align: end;
    font-size: 1vw;
    font-weight: 500;
}
.wave-Up{
  height: 25vh;
}
.wave-Down{
  @include mixins.wave-style-down;
  height: 10vh;
  bottom: -30px;
}

 .box1{
  @include mixins.ourTeam-box-style;
  transform: rotate(-3deg)
  // height: 30vh;
  // width: 15vw;
  // border-radius: 1vw;
  // background-color: #DDE8CE;
  // position: relative;
  // margin: 0 5vb;
  // transform: rotate(-3deg);
  
}
.box2{
  @include mixins.ourTeam-box-style;
  transform:rotate(3deg);
  // height: 30vh;
  // width: 15vw;
  // border-radius: 1vw;
  // background-color: #DDE8CE;
  // position: relative;
  // margin: 0 5vb;
 
}
.story-body-2{
  max-width: 90vw;
}
.waveUp {
  height: 120px;

}

.waveDown {
  @include mixins.wave-style-down;
    height: 110px;
    position: relative;
    
  
}
.main-box{
    display: flex;
    justify-content: space-around;
    // margin-bottom: 6vb;
    
     margin-left: 20vh;
     margin-right: 20vh;
     margin-bottom: 9.5vh;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 95px;
}
.header-text{
  color: var(--light-Green);
  font-family: Chobani-Serif-Web-Semibold-Regular;
  margin-top: 10vb;
  margin-bottom: 9vh;
  font-size: 4vb;
}

@media (min-width:320px) and (max-width:480px){
  .main-box{
            flex-direction: row;
            gap: 5.5vw;
            align-items: center;
            margin-top: -5vh;
            margin-bottom: 5vh;
        }

        .box1{
        @include mixins.ourTeam-box-style;
        transform: rotate(-3deg);
        height: 12vh; 
        width: 55vw;

}

.box2{
  @include mixins.ourTeam-box-style;
  transform:rotate(3deg);
  height: 12vh; 
  width: 55vw;
}
.story-body-1-main{
  width: 70vw;
  color: var(--light-Green);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.para1{
  text-align: left;
  width: 71vw;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 3vw;
}
.para2{
  text-align: end;
  font-size: 3vw;
  font-weight: 500;
  margin-top: 2vh;
}





@mixin wave($fill, $height, $transform: none, $position: static, $bottom: auto, $left: auto, $right: auto) {
  fill: $fill;
  display: flex;
  width: 100%;
  height: $height;
  transform: $transform;
  position: $position;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin waveOptions($fill, $height: 10px, $amplitude: 10, $speed: 0.15s, $points: 2) {
  @include wave($fill, $height);
  // amplitude, speed, and points are not standard CSS properties, these should be part of Wave component props
}

}
    
@media (min-width:481px) and (max-width:576px){
  .main-box{
            flex-direction: row;
            gap: 4vw;
            align-items: center;
            margin-top: -5vh;
            margin-bottom: 5vh;
        }

        .box1{
        @include mixins.ourTeam-box-style;
        transform: rotate(-3deg);
        height: 19vh; 
        width: 30vw;

}

.box2{
  @include mixins.ourTeam-box-style;
  transform:rotate(3deg);
  height: 19vh; 
  width: 30vw;


}

.story-body-1-main{
  width: 70vw;
  color: var(--light-Green);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.para1{
  text-align: left;
  width: 71vw;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 3vw;
}
.para2{
  text-align: end;
  font-size: 3vw;
  font-weight: 500;
  margin-top: 2vh;
}





@mixin wave($fill, $height, $transform: none, $position: static, $bottom: auto, $left: auto, $right: auto) {
  fill: $fill;
  display: flex;
  width: 100%;
  height: $height;
  transform: $transform;
  position: $position;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin waveOptions($fill, $height: 10px, $amplitude: 10, $speed: 0.15s, $points: 2) {
  @include wave($fill, $height);
  // amplitude, speed, and points are not standard CSS properties, these should be part of Wave component props
}

}
  
    
@media (min-width:577px) and (max-width:768px){
  .main-box {
     flex-direction: row;
     gap: 3vw;
     align-items: center;
     margin-top: -6vh;
     margin-bottom: 5vh;
     }
  .box1 {
        @include mixins.ourTeam-box-style;
        transform: rotate(-3deg);
        height: 20vh; 
        width: 20vw;
        }

        .box2{
        @include mixins.ourTeam-box-style;
        transform:rotate(3deg);
        height: 20vh; 
        width: 20vw;
}
  .story-body-1-main{
  width: 70vw;
  color: var(--light-Green);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.para1{
  text-align: left;
  width: 71vw;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 2.2vw;
  margin-top: 5vb;
}
.para2{
  text-align: end;
  font-size: 2.5vw;
  font-weight: 500;
  margin-top: 2vh;
}

@mixin wave($fill, $height, $transform: none, $position: static, $bottom: auto, $left: auto, $right: auto) {
  fill: $fill;
  display: flex;
  width: 100%;
  height: $height;
  transform: $transform;
  position: $position;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin waveOptions($fill, $height: 10px, $amplitude: 10, $speed: 0.15s, $points: 2) {
  @include wave($fill, $height);
  // amplitude, speed, and points are not standard CSS properties, these should be part of Wave component props
}

}
@media (min-width:769px) and (max-width:992px) {
  .main-box {
    flex-direction: row;
    gap: 3vw;
    align-items: center;
    margin-top: -2vh;
    margin-bottom: 7vh;
    }
 .box1 {
       @include mixins.ourTeam-box-style;
       transform: rotate(-3deg);
       height: 25vh; 
       width: 20vw;
       }

       .box2{
       @include mixins.ourTeam-box-style;
       transform:rotate(3deg);
       height: 25vh; 
       width: 20vw;
}
 .story-body-1-main{
 width: 70vw;
 color: var(--light-Green);
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 10;
}
.para1{
 text-align: left;
 width: 71vw;
 margin-bottom: 0;
 font-weight: 600;
 font-size: 2.2vw;
 margin-top: 5vb;
}
.para2{
 text-align: end;
 font-size: 2.5vw;
 font-weight: 500;
 margin-top: 2vh;
}

@mixin wave($fill, $height, $transform: none, $position: static, $bottom: auto, $left: auto, $right: auto) {
 fill: $fill;
 display: flex;
 width: 100%;
 height: $height;
 transform: $transform;
 position: $position;
 bottom: $bottom;
 left: $left;
 right: $right;
}

@mixin waveOptions($fill, $height: 10px, $amplitude: 10, $speed: 0.15s, $points: 2) {
 @include wave($fill, $height);
 // amplitude, speed, and points are not standard CSS properties, these should be part of Wave component props
}

}
