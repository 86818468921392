.seconddiv {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: normal;
  /* height: 20vh; */
  /* margin-top: 19px; */
  height: calc(17vh + 0rem);
  gap: 5.7vh/* Reserve space for the submission message */  
}
.submission-message {
  display: inline-flex;
  align-items: center;
  
 
}
.offer_div{

  width: 20vw;
  font-size: 2vw;
}
.icon-image {
  height: 25px;
  margin-right: 5px;
}

.submit {
  color: var(--light-Green);
  font-weight: 600;
  background-color: var(--light-color);
  font-size: 1.2vw;
}
#column1Header {
  font-size: 2.5vw;
  font-weight:600;
  cursor: pointer;
}
/* 
.seconddiv {
  margin-right: 60px;
} */
.input1 {
    background-color: var(--light-color);
    color: var(--light-Green);
    padding-left: 6px;
    font-size: 1vw;
    width: fit-content;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .input1 {
      background-color: var(--light-color);
      color: var(--light-Green);
      padding-left: 6px;
      width: max-content;
      font-size: 1.8vw;
    }
    .firstmaindiv{
      display: flex;
      flex-direction: column;
      gap: 25px;
      /* justify-content: space-around; */
      margin-bottom: 3%;
      margin-top: 59px;
      align-items: center;
  }
  
  .seconddiv {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: normal;
    gap: 1rem;
    font-size: 2vw;
    margin-top: 50px;
  }
    #column1Header{
      font-size: 2.4vh;
      width: max-content;
    }
    .submit {
      font-size: 2.2vw;
    }
    .offer_div
    {
      width:37vw;
    }
  }
  @media (min-width: 481px) and (max-width: 576px) {
    .input1 {
      background-color: var(--light-color);
      color: var(--light-Green);
      padding-left: 6px;
      width: max-content;
      font-size: 1.8vw;
    }
    .firstmaindiv{
      display: flex;
      flex-direction: column;
      gap: 25px;
      /* justify-content: space-around; */
      margin-bottom: 3%;
      margin-top: 59px;
      align-items: center;
  }
  
  .seconddiv {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: normal;
    gap: 1rem;
    font-size: 2vw;
    margin-top: 50px;
  }
    #column1Header{
      font-size: 3.5vh;
      width: max-content;
    }
    .submit {
      font-size: 2.2vw;
    }
    .offer_div
    {
      width:37vw;
    }
   }
  @media (min-width: 577px) and (max-width: 768px) {
    .input1 {
      background-color: var(--light-color);
      color: var(--light-Green);
      padding-left: 6px;
      width: max-content;
      font-size: 1.8vw;
    }
    .firstmaindiv{
      display: flex;
      flex-direction: column;
      gap: 25px;
      /* justify-content: space-around; */
      margin-bottom: 3%;
      margin-top: 59px;
      align-items: center;
  }
  
  .seconddiv {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: normal;
    gap: 1rem;
    font-size: 2vw;
    margin-top: 54px;
  }
    #column1Header{
      font-size: 4.6vh;
      width: max-content;
    }
    .submit {
      font-size: 2.2vw;
    }
    .offer_div
    {
      width:37vw;
    }
  }
  @media (min-width:769px) and (max-width: 992px) {
    .input1 {
      background-color: var(--light-color);
      color: var(--light-Green);
      padding-left: 6px;
      width: max-content;
      font-size: 1.8vw;
    }
    .firstmaindiv{
      display: flex;
      flex-direction: column;
      gap: 25px;
      /* justify-content: space-around; */
      margin-bottom: 3%;
      margin-top: 59px;
      align-items: center;
  }
  
  .seconddiv {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: normal;
    gap: 1rem;
    font-size: 2vw;
    margin-top: 50px;
  }
    #column1Header{
      font-size: 5.9vh;
      width: max-content;
    }
    .submit {
      font-size: 2.2vw;
    }
    .offer_div
    {
      width:37vw;
    }
  }
  @media (min-width:993px) {
    #column1Header{
      font-size: 2vw !important;
    }
    
  }