.ProductMain {
    justify-content: space-around;
    color: var(--light-Green);
    background-color: #f8f9fa;
    padding-top: 5px;    
}   
.closeButton{
    margin-left: 95vw;
}
.ProductSubMain {
    display: flex;
    justify-content: space-evenly;
}
.ProductMargin {

    text-align: justify;
    width: 450px;
    margin-top: 20px;
}
.ProductMargi {
    text-align: center;
    margin-top: 80px;
}
.ProductText2 {
    text-align: center;
    margin-bottom: 5px;
}
.ProductAlign {
    justify-content: center;
    color: var(--light-Green);
}
.ProductLine {
    width: 500px;
    height: 3px;
    background-color: var(--light-Green);
    margin-top: 30px;
    margin-bottom: 30px;
}
.ProductText {
    display: flex;
    justify-content: space-evenly;
    color: var(--light-Green);
    font-family: "Proxima-Nova-Alt-Light";
    /* margin-right: 155px; */   
}
.ProductTextTwo {
    text-align: right;
    padding-right: 71px;
    margin-bottom: 5px;
}
.ProductColor {
    color: var(--light-Green);
    line-height: 0.5;  
}
.NutritionKey{
    display: inline-block;
    width:100px;
    position: relative;
    left:0px;
    margin-right: 120px;
    margin-top: 10px;
}
.NutritionValue{
display: inline-block;
}

@media (min-width:320px) and (max-width: 480px){
    .ProductSubMain {
        display: flex;
        justify-content: space-evenly;
    }
    .ProductMargi {
        text-align: center;
        margin-top: 15px;
        width: 40vw;
    }
    h4 {
        font-size: calc(0.375rem + .3vw);
    }
    li {
        font-size: 1.8vw;
    }
    .closeButton{
        font-size: 3.5vw;
    }
.ProductMargin {
    text-align: justify;
    width: 40vw;
    margin-top: 20px;
}
.ProductAlign {
justify-content: center;
color: var(--light-Green);
width: 40vw;
}
.ProductLine {
width: 40vw;
height: 1px;
background-color: var(--light-Green);
margin-top: 0px; 
margin-bottom: 10px;
}
.ProductText {
    display: flex;
    justify-content: space-evenly;
    color: var(--light-Green);
    font-family: "Proxima-Nova-Alt-Light";
    margin-right: 8px;
}
ul{
    padding-left: 0px;
}
.ProductTextTwo {
    text-align: right;
    padding-right: 20px;
}
.NutritionKey{
display: inline-block;
width: 22vw;
position: relative; 
left: 0px;
 margin-right: 0px
}
}
@media (min-width:481px) and (max-width: 576px){
    .ProductSubMain {
        display: flex;
        justify-content: space-evenly;
    }
    .ProductMargi {
        text-align: center;
        margin-top: 15px;
        width: 40vw;
    }
    h4 {
        font-size: calc(0.575rem + .3vw);
        font-weight: normal;
    }
    li {
        font-size: 1.7vw;
    }
    .closeButton{
        font-size: 3vw;
    }
.ProductMargin {
    text-align: justify;
    width: 40vw;
    margin-top: 20px;
}
.ProductAlign {
justify-content: center;
color: var(--light-Green);
width: 40vw;
}
.ProductLine {
    width: 40vw;
    height: 1px;
    background-color: var(--light-Green);
    margin-top: 0px;
    margin-bottom: 9px;
}
.ProductText {
display: flex;
justify-content: space-evenly;
color: var(--light-Green);
font-family: "Proxima-Nova-Alt-Light";
margin-right: 10px;
}
.ProductTextTwo {
    text-align: right;
    padding-right: 18px;
}
.NutritionKey{
display: inline-block;
width: 22vw;
position: relative; 
left: 0px;
 margin-right: 0px
}
}
@media (min-width:577px) and (max-width: 768px){
    .ProductSubMain {
        display: flex;
        justify-content: space-evenly;
    }
    .ProductMargi {
        text-align: center;
        margin-top: 15px;
        width: 40vw;
    }
    h4 {
        font-size: calc(0.775rem + .3vw);
        font-weight: normal;
    }
    li {
        font-size: 1.6vw;
    }
    .closeButton{
        font-size: 3vw;
    }
.ProductMargin {
    text-align: justify;
    width: 40vw;
    margin-top: 20px;
}
.ProductAlign {
justify-content: center;
color: var(--light-Green);
width: 40vw;
}
.ProductLine {
width: 40vw;
height: 1px;
background-color: var(--light-Green);
margin-top: 0px; 
 margin-bottom: 10px;
}
.ProductText {
display: flex;
justify-content: space-evenly;
color: var(--light-Green);
font-family: "Proxima-Nova-Alt-Light";
margin-right: 25px;
}
.ProductTextTwo {
    text-align: right;
    padding-right: 35px;
}
.NutritionKey{
display: inline-block;
width: 22vw;
position: relative; 
left: 0px;
 margin-right: 0px
}
}

@media (min-width:769px) and (max-width: 991px){
    .ProductSubMain {
        display: flex;
        justify-content: space-evenly;
    }
    .ProductMargi {
        text-align: center;
        margin-top: 15px;
        width: 40vw;
    }
    h4 {
        font-size: calc(0.975rem + .3vw);
        font-weight: normal;
    }
.ProductMargin {
    text-align: justify;
    width: 40vw;
    margin-top: 20px;
}
.ProductAlign {
justify-content: center;
color: var(--light-Green);
width: 40vw;
}
.ProductLine {
width: 40vw;
height: 1px;
background-color: var(--light-Green);
margin-top: 0px; 
 margin-bottom: 10px;
}
.ProductText {
display: flex;
justify-content: space-evenly;
color: var(--light-Green);
font-family: "Proxima-Nova-Alt-Light";
margin-right: 35px;
}
.ProductTextTwo {
    text-align: right;
    padding-right: 46px;
}
.NutritionKey{
display: inline-block;
width: 22vw;
position: relative; 
left: 0px;
 margin-right: 0px
}
  
}
@media (min-width:992px) {

li{
    font-size: 1.3vw !important;
}
}