.content-div {
  width: 40vw;
  margin-top: 10vh;
}

.heroMobile {
  display: none;
}

.custom-h1,
.custom-p {
  color: #5B930B;
  font-family: 'Chobani-Serif-Web-Semibold-Regular';
  font-size: 5.2vw;
  width: max-content;
}

/* .custom-h1{
    margin-top: 10vh;
    
} */
.custom-p {

  font-size: 4vw;
  word-spacing: 2px;
  font-family: "Proxima-Nova-Regular";
  margin-top: -9px;
}

.custom-btn {

  /* margin-left: 15%;
        margin-right: 13%; */
  /* padding:; */
  color: white;
  background-color: #5B930B;
  border-radius: 1vw;
  font-family: 'FontsFree-Net-Proxima-Nova-Sbold';
  border: none;
  font-size: 2.3vw;
  width: 18vw;
  margin-top: 17vh;
  height: 10vh;
  margin-bottom: 13vh;
}

/* .image{
    margin-left: 60%;
    margin-top: -40%;
    margin-left: 40%;   
    
} */
.image img {
  height: 95vh;
  position: absolute;
  top: 27vh;
  right: 5vw;
}

.joyfresh-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
} 



@media (min-width:320px) and (max-width:480px) {

  .custom-h1 {
    font-size: 8vw;
    width: max-content;
    margin-top: 0vh;
  }

  .heroMobile {
    display: block;
  }

  .heroMobile img {
    height: 40vh;
    position: absolute;
    right: 17px;
    top: 15vh;
  }

  .image img {
    display: none;
  }

  .custom-p {
    font-size: 6vw;
    width: max-content;
    font-weight: 600;
  }

  .custom-btn {
    width: 31vw;
    font-size: 5vw;
    height: 6vh;
    border-radius: 3vw;
    margin-top: 6vh;
    margin-bottom: 3vh;
  }

  .content-div {
    margin-top: 0px;
  }


}


@media (min-width:481px) and (max-width:576px) {

  .custom-h1 {
    font-size: 9vw;
    width: max-content;
    margin-top: 0vh;
  }

  .image img {
    display: none;
  }

  .heroMobile {
    display: block;
  }

  .heroMobile img {
    height: 60vh;
    position: absolute;
    right: 9px;
    top: 22vh
  }

  .custom-p {
    font-size: 6vw;
    width: max-content;
    font-weight: 600;
  }

  .custom-btn {
    width: 31vw;
    font-size: 5vw;
    height: 6vh;
    border-radius: 2vw;
    margin-top: 10vh;
    margin-bottom: 6vh;
  }

}

@media (min-width:577px) and (max-width:768px) {

  .custom-h1 {
    font-size: 8.5vw;
    width: max-content;
    margin-top: 2vh;
  }

  .image img {
    display: none;
  }

  .heroMobile {
    display: block;
  }

  .heroMobile img {
    height: 68vh;
    position: absolute;
    right: 9px;
    top: 22vh;
  }

  .custom-p {
    font-size: 6vw;
    width: max-content;
    font-weight: 600;
  }

  .custom-btn {
    width: 31vw;
    font-size: 5vw;
    height: inherit;
    border-radius: 2vw;
    margin-top: 10vh;
    margin-bottom: 6vh;
  }
}

@media (min-width:769px) and (max-width:992px) {

  .custom-h1 {
    font-size: 9vw;
    width: max-content;
    margin-top: 2vh;
  }

  .image img {
    display: none;
  }

  .heroMobile {
    display: block;
  }

  .heroMobile img {
    height: 74vh;
    position: absolute;
    right: 10px;
    top: 24vh;
  }

  .custom-p {
    font-size: 6vw;
    width: max-content;
    font-weight: 600;
  }

  .custom-btn {
    width: 31vw;
    font-size: 5vw;
    height: inherit;
    border-radius: 2vw;
    margin-top: 10vh;
    margin-bottom: 6vh;
  }
}