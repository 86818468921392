.nav_img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.navbar-nav {
  /* width: 30vw !important; */
  align-items: center;
}

.userName{
  display: block;

  font-family: inherit;
  max-width: 8vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.img_cont {
  width: 8.4vw;
}

.nav_container {
  margin-left: 4vw !important;
  margin-right: 2vw !important;
}

.icon {
  width: 31vw;
}

.nav_Links {
  color: var(--light-Green);
  text-decoration: none;
  font-size: 1.3vw;
  margin-right: 1vw;
  /* margin-left: 3px; */
}

.nav_Links:hover {
  color: var(--light-Green);
  text-decoration: underline;
  font-size: 1.3vw;
  margin-right: 1vw;
  /* margin-left: 3px; */
  text-underline-offset: 0.30em;
}

.nav_main {
  height: 13vh;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown img {
  width: 1.2vw;
  margin-left: 4px;
  margin-right: 7px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 7.8vw;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: var(--light-Green);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}


@media (min-width: 320px) and (max-width: 480px) {

  /* Styles for xs breakpoint */
  .nav_img {
    width: 25vw;
    height: 100%;
    margin-left: 11vw;
  }

  .toggleicon {
    position: absolute;
    background-color: hsl(85, 36%, 86%);  
    width: 40%;
    height: 100vh;
    right: 0%;
    top: 100%;
    text-align: end;
    z-index: +999;
  }

  .navbar-nav {
    width: 30vw !important;
    align-items: end;
    margin-left: 4vw;
  }

  .icon {
    align-items: start;
    position: relative;
  }

  .toggleicon1 {
    position: relative;
    border: none !important;
    /* top: 30px; */
  }
.toggleicon1 .navbar-toggler-icon {
  background-image: url(../../assets/Logo/Profileicon.png) !important;
}
  /* .dropdown{
   display: none;
 } */
  .nav_Links {
    color: var(--light-Green);
    text-decoration: none;
    font-size: 3vw;
    margin-bottom: 20px;
  }

  .nav_Links:hover {
    color: var(--light-Green);
    text-decoration: none;
    font-size: 3vw;
    margin-bottom: 20px;
  }

  .dropdown-content {
    position: relative;
    font-size: 2vw;
  }

  .dropdown img {
    width: 3vw;
    margin-right: 1vw;
  }
}

@media (min-width: 481px) and (max-width: 576px) {
  .nav_img {
    width: 20vw;
    height: 100%;
    margin-left: 9vw;
  }

  .toggleicon {
    position: absolute;
    background-color: hsl(85, 36%, 86%);
    width: 40%;
    height: 100vh;
    right: 0%;
    top: 100%;
    text-align: end;
    z-index: +999;
  }

  .navbar-nav {
    width: 30vw !important;
    align-items: end;
    margin-left: 4vw;
  }

  .icon {
    align-items: start;
    position: relative;
  }

  .toggleicon1 {
    position: relative;
    border: none !important;
    /* top: 30px; */
  }

  /* .dropdown{
    display: none;
  } */
  .nav_Links {
    color: var(--light-Green);
    text-decoration: none;
    font-size: 2.2vw;
    margin-bottom: 20px;
  }

  .nav_Links:hover {
    color: var(--light-Green);
    text-decoration: none;
    font-size: 2.2vw;
    margin-bottom: 20px;
  }

  .dropdown-content {
    position: relative;
    font-size: 2vw;
  }

  .dropdown img {
    width: 2.2vw;
    margin-right: 1vw;
  }
}

@media (min-width: 576px) and (max-width: 768px) {

  .nav_img {
    width: 18vw;
    height: 100%;
    margin-left: 10vw;
  }

  .toggleicon {
    position: absolute;
    background-color: hsl(85, 36%, 86%);
    width: 40%;
    height: 100vh;
    right: 0%;
    top: 100%;
    text-align: end;
    z-index: +999;
  }

  .navbar-nav {
    width: 30vw !important;
    align-items: end;
    margin-left: 8vw;
  }

  .dropdown img {
    margin-right: 1vw;
  }

  .icon {
    align-items: start;
    position: relative;
  }

  .toggleicon1 {
    position: absolute;
    top: 3vh;
    border: none !important;
    right: 2vw;
  }

  /* .dropdown{
    display: none;
  } */
  .nav_Links {
    margin-bottom: 30px;
  }

  .dropdown-content {
    position: relative;
  }

  .dropdown img {
    margin-right: 1vw;
    width: 2.3vw;
  }

  .nav_Links {
    color: var(--light-Green);
    text-decoration: none;
    font-size: 2.3vw;
    margin-bottom: 20px;
  }

  .nav_Links:hover {
    color: var(--light-Green);
    text-decoration: none;
    font-size: 2.3vw;
    margin-bottom: 20px;
  }

}

@media (min-width: 769px) and (max-width: 991px) {
  .nav_img {
    width: 15vw;
    height: 100%;
    margin-left: 7vw;
  }

  .toggleicon {
    position: absolute;
    background-color: hsl(85, 36%, 86%);
    width: 40%;
    height: 100vh;
    right: 0%;
    top: 100%;
    text-align: end;
    z-index: +999;
  }

  .navbar-nav {
    width: 30vw !important;
    align-items: end;
    margin-left: 8vw;
  }

  .dropdown img {
    margin-right: 1vw;
  }

  .icon {
    align-items: start;
    position: relative;
  }

  .toggleicon1 {
    position: absolute;
    top: 3vh;
    right: 2vw;
    border: none !important;
  }

  /* .dropdown{
   display: none;
 } */
  .nav_Links {
    color: var(--light-Green);
    text-decoration: none;
    font-size: 2.3vw;
    margin-bottom: 20px;
  }

  .nav_Links:hover {
    color: var(--light-Green);
    text-decoration: none;
    font-size: 2.3vw;
    margin-bottom: 20px;
  }

  .dropdown-content {
    position: relative;
  }

  .dropdown img {
    margin-right: 1vw;
    width: 2.3vw;
  }


}
@media (max-width: 992px) {
.userName{
  display: block;

  font-family: inherit;
  max-width: 16vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
}
@media (min-width: 992px) {

  /* Styles for xl breakpoint */
  .profile {
    display: none;

  }

  .navbar-nav {
    /* width: 35vw !important; */
    /* flex-direction: row; */
    justify-content: center;
    margin-right:3.5vw;
  }

  .nav_container {
    margin-left: 4vw !important;
    margin-right: 0vw !important;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    right: 1px;
    min-width: 120px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
.toggleicon1{
  border: none !important;
}
}
