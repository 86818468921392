.alert {
    background-color: var(--white-color);
    position: absolute;
    color:var(--mid-Green); 
    padding: 2px;
    border: 1px solid #f5c6cb; 
    border-radius: 4px;
    margin-bottom: 0px;
   
  }
  