@use '../../../Styles/mixins.scss' as mixins;

.body-content-1 {
    display: flex;
    justify-content: space-around;
    color: var(--light-Green);
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5vw;
    z-index: 2;
   
}
.body-content-2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -8vh;
  
}
.body-content-2 a{
justify-content: space-around;


}

.tab {
    cursor: pointer;
    padding: 2vh 2vh;
    border-radius: 5px;
    color: var(--light-Green);
    text-decoration: none;
    // margin: 60vh 4.2vh;
    margin-left: 1.2vh;
    position: relative;
    top: -3.5vh;
  

    // font-size: 1vw;
}

.tab:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 0.1px;
}

//common css
   .menuBar-btn {
    color: var(--light-Green);
       cursor: pointer;
       background-color: var(--wave-fill-Green);
       border: none;
   }
   
.waveUp {
    height: 14vh;
    z-index: -999;
}

.waveDown {
    @include mixins.wave-style-down;
    height: 12vh;
    margin-top: 5vh;
    z-index: -999;
    margin-bottom: 50vh;
// @320
    // height: 10vh;
    // position: absolute; //mixins
    // left:0;//mixins
    // right:0;//mixins
    // margin-bottom: 41vh;
    
    // margin-top: -6vh;
}

//new mobile design media
@media (min-width: 320px) and (max-width: 480px) {
    .menuBar-btn {
            font-size: 4.7vw;
                position: relative;
                top: -45.8px;
        }
    
        .tab {
            font-size: 3vw;
            margin-left: -0.8vw;
            position: relative;
            top: -39px;
        } 
}

@media (min-width: 481px) and (max-width: 576px) {
    .menuBar-btn {
            font-size: 4.7vw;
                position: relative;
                top: -34.8px;
                
        }
    
        .tab {
            font-size: 3vw;
            margin-left: -0.8vw;
            position: relative;
            top: -26.8px;
        }
}

@media (min-width: 577px) and (max-width: 768px) {
    .menuBar-btn {
            font-size: 4.7vw;
            position: relative;
            top: -40.8px;
            // color: var(--light-Green);
            cursor: pointer;
            // background-color: var(--wave-fill-Green);
            //     border: none;
        }
    
        .tab {
            font-size: 3vw;
            // margin-left: -0.8vw;
            position: relative;
            top: -30.8px;
        }
}
//mobile design class
// @media (min-width:320px) and (max-width:480) {
// .body-content-1-m {
//     flex-direction: row;
//     font-size: 3vw;
//     // position: relative; 
//     // top: 20vh;

// }

// .menuBar-btn-m {
//     font-size: 6vw;
//     margin-bottom: 59.5vh;
// }

// .tab-m {
//     padding: 1.5vh 1.5vh;
//     font-size: 3.2vw;
//     margin: 0 -0.5vh;
//     position: relative;
//     top: 20vh;
// }
// }
// @media (max-width: 768px) {
//     .body-content-1 {
//         flex-direction: row;
//         font-size: 3vw;
//         // position: relative; 
//         // top: 20vh;
        
//     }

//     .menuBar-btn {
//         font-size: 6vw;
//                 margin-bottom: 59.5vh;
//     }

//     .tab {
//         padding: 1.5vh 1.5vh;
//         font-size: 3.2vw;
//         margin: 0 -0.5vh;
//         position: relative;
//             top: 20vh;
//     }
// }

@media (min-width:320px) and (max-width:480){
    //768
    // .body-content-1 {
    //     flex-direction: row;
    //     font-size: 3vw;
    //     // position: relative; 
    //     // top: 20vh;

    // }

    // .menuBar-btn {
    //     font-size: 6vw;
    //     margin-bottom: 59.5vh;
    // }

    // .tab {
    //     padding: 1.5vh 1.5vh;
    //     font-size: 3.2vw;
    //     margin: 0 -0.5vh;
    //     position: relative;
    //     top: 20vh;
    // }

    //prev
// .body-content-1 {
//         flex-direction: row;
//         font-size: 3vw;
//         // position: relative; 
//         // top: 20vh;

//     }

//     .menuBar-btn {
//         font-size: 6vw;
//         margin-bottom: 59.5vh;
//     }

//     .tab {
//         padding: 1.5vh 1.5vh;
//         font-size: 3.2vw;
//         margin: 0 -0.5vh;
//         position: relative;
//         top: 20vh;
//     }
                                 
}

 @media (min-width:481px) and (max-width:576) {
    // 768
    // .body-content-1 {
    //         flex-direction: row;
    //         font-size: 3vw;
    //         // position: relative; 
    //         // top: 20vh;
    
    //     }
    
    //     .menuBar-btn {
    //         font-size: 6vw;
    //         margin-bottom: 59.5vh;
    //     }
    
    //     .tab {
    //         padding: 1.5vh 1.5vh;
    //         font-size: 3.2vw;
    //         margin: 0 -0.5vh;
    //         position: relative;
    //         top: 20vh;
    //     }
        
        //prev
//     .menuBar-btn {
//             color: var(--dark-Green);
//             cursor: pointer;
//             background-color: var(--wave-fill-Green);
//             border: none;
//             font-size: 4vw;
//         }
        
//     .tab {
//         cursor: pointer;
//         padding: 2vh 2vh;
//         color: var(--light-Green);
//         font-size: 3.2vw;
//         color: var(--light-Green);
//         text-decoration: none;
//         margin: 0vh 0.5vh;

//     }
 }

 @media (min-width:577px) and (max-width:768) {
    // 768
    // .body-content-1 {
    //         flex-direction: row;
    //         font-size: 3vw;
    //         // position: relative; 
    //         // top: 20vh;
    
    //     }
    
    //     .menuBar-btn {
    //         font-size: 6vw;
    //         margin-bottom: 59.5vh;
    //     }
    
    //     .tab {
    //         padding: 1.5vh 1.5vh;
    //         font-size: 3.2vw;
    //         margin: 0 -0.5vh;
    //         position: relative;
    //         top: 20vh;
    //     }

//prev
//     .menuBar-btn {
//             color: var(--dark-Green);
//             cursor: pointer;
//             background-color: var(--wave-fill-Green);
//             border: none;
//             font-size: 4vw;
//         }
    
//     //   .tab {
//     //     cursor: pointer;
//     //     padding: 2vh 2vh;
//     //     color: var(--light-Green);
//     //     font-size: 2.7vw;
//     //     text-decoration: none;
//     //     margin: 0vh 0.5vh;
//     //     position: relative;
//     //     top: 1.5vh
//     // }    
      }